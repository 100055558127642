// gatsby
import { Link } from 'gatsby'
// react
import * as React from 'react'
// react bootstrap components
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// ui
import 'bootstrap/dist/css/bootstrap.css'
import logo from 'images/plant-2560.png'
import MCOLogo from 'images/MCO-Graphic.png'
import EigenLogo from 'images/Eig-Graphic.png'
import TLLogo from 'images/TL-Graphic.png'
import { GiAtom } from 'react-icons/gi'
// internal components
import Footer from 'components/footer'
import Layout from '../components/layout'
import './styles.css'

const IndexPage = (props) => {
  return (
    <>
      <Container fluid>
        <Row
          className='justify-content-center align-items-center'
          fluid
          style={{
            backgroundImage: 'url(' + logo + ')',
            backgroundRepeat: 'no-repeat',
            width: 'auto',
            height: '210px'
          }}
        >
          <Col xs='auto'>
            <h1
              className='display-3 lh-1 text-white'
              style={{ fontWeight: '100' }}
            >
              <GiAtom size={64} /> BW Nuclear | Home
            </h1>
          </Col>
        </Row>
      </Container>
      <br />
      <div>
        <main>
          <Container>
            <CardGroup className='justify-content-center'>
              <Row>
                <Col>
                  <Card style={{ width: '20rem', border: 'none', height: '24rem', backgroundColor: '#D3D3D3', borderRadius: '2px' }} className='shadow p-3 mb-5  mx-auto card-background'>
                    <br />
                    <Link to='/mco'>
                      <Card.Img variant='top' src={MCOLogo} className='img-fluid card-animation' />
                    </Link>
                    <Card.Body>
                      <h1 className='lead card-info' style={{ fontWeight: '400' }}>MCO.ai</h1>
                      <p className=''>A prediction platform for Moisture Carryover in BWRs</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ width: '20rem', border: 'none', height: '24rem', backgroundColor: '#D3D3D3', borderRadius: '2px' }} className='shadow p-3 mb-5  mx-auto card-background'>
                    <Link to='/eigen'>
                      <Card.Img variant='top' src={EigenLogo} className='img-fluid card-animation' />
                    </Link>
                    <Card.Body>
                      <h1 className='lead card-info' style={{ fontWeight: '400' }}>eigenvalue.ai</h1>
                      <p className=''>A prediction platform for hot k-effective in BWRs</p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ width: '20rem', border: 'none', height: '24rem', backgroundColor: '#D3D3D3', borderRadius: '2px' }} className='shadow p-3 mb-5  mx-auto card-background'>
                    <br />
                    <Link to='/tl'>
                      <Card.Img variant='top' src={TLLogo} className='img-fluid card-animation pt-4' />
                    </Link>
                    <Card.Body>
                      <h1 className='lead card-info' style={{ fontWeight: '400' }}>ThermalLimit.ai</h1>
                      <p className=''>A prediction platform for Thermal Limit Bias reduction in BWRs</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </CardGroup>
            <br /><br /><br /> <br />
            <br />
          </Container>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default IndexPage
